// Core
import { useCallback, useEffect, useState } from "react";

// Definitions
import { ConsentCookiesType } from "../types";

// Utils
import { CONSENT_COOKIE_KEY } from "../constants";
import { cookiesService } from "@/client/utils/cookies";

type UseConsentCookiesType = {
  isOpenConsentCookies: boolean;
  onAllowAllCookies: () => void;
  onRejectAllCookies: () => void;
  setConsentCookies: (data: ConsentCookiesType) => void;
};

const defaultConsentCookies: ConsentCookiesType = {
  necessary: false,
  analytical: false,
};

export const useConsentCookies = (): UseConsentCookiesType => {
  const [isOpenConsentCookies, setIsOpenConsentCookies] = useState(false);

  useEffect(() => {
    const consentCookies = cookiesService.fromJson<ConsentCookiesType>(CONSENT_COOKIE_KEY);
    const isAcceptedCookies = !!consentCookies;
    let cookieAppearanceTimeout: NodeJS.Timeout;

    if (!isAcceptedCookies) {
      cookieAppearanceTimeout = setTimeout(() => setIsOpenConsentCookies(true), 2000);
    }

    return () => clearTimeout(cookieAppearanceTimeout);
  }, []);

  const setConsentCookies = useCallback((data: ConsentCookiesType) => {
    cookiesService.toJson<ConsentCookiesType>(CONSENT_COOKIE_KEY, data);
    setIsOpenConsentCookies(false);
  }, []);

  const onAllowAllCookies = useCallback(() => {
    setConsentCookies({
      ...defaultConsentCookies,
      necessary: true,
      analytical: true,
    });
  }, []);

  const onRejectAllCookies = useCallback(() => {
    setConsentCookies({
      ...defaultConsentCookies,
      necessary: true,
    });
  }, []);

  return {
    isOpenConsentCookies,
    onAllowAllCookies,
    onRejectAllCookies,
    setConsentCookies,
  };
};
