// Components
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { InputSwitch } from "@/client/components/ui/FormFields/InputSwitch";

// Definitions
import { IInputSwitchProps } from "@/client/components/ui/FormFields/InputSwitch/InputSwitch.types";

interface IWrappedFormInputSwitch<ControlT extends FieldValues> extends IInputSwitchProps {
  name: Path<ControlT>;
  control?: Control<ControlT>;
}

export const WrappedFormInputSwitch = <T extends FieldValues>(
  props: IWrappedFormInputSwitch<T>,
) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputSwitch {...rest} {...field} ref={field.ref} />}
    />
  );
};
