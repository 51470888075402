"use client";

// Core
import { ReactNode, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { initTranslations } from "@/client/i18n";
import { Resource, createInstance } from "i18next";

export const TranslationsProvider = ({
  children,
  locale,
  namespaces,
  resources,
}: {
  children: ReactNode;
  locale: string;
  namespaces: string[];
  resources?: Resource;
}) => {
  const i18n = createInstance();

  void (async () => {
    await initTranslations(locale, namespaces, i18n, resources);
  })();

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense>{children}</Suspense>
    </I18nextProvider>
  );
};
