// Core
import cx from "classnames";

// Definitions
import type { PropsWithChildren } from "react";

// Utils
import st from "./styles.module.css";

type BorderDecorProps = PropsWithChildren & {
  className?: string;
  width?: "1" | "2";
  color?: "gray-3" | "gray-9" | "white" | "cta-12";
  radius?: "2" | "4" | "6" | "circle";
  gutter?: "4" | "8" | "12" | "14" | "16" | "24" | "none";
  gutterPosition?: "top" | "bottom" | "left" | "right" | "vertical" | "horizontal";
  position?: "top" | "bottom" | "left" | "right" | "vertical" | "horizontal";
};

export const BorderDecor = (props: BorderDecorProps) => {
  const {
    children,
    className,
    width = "1",
    color = "gray-3",
    radius,
    gutter,
    gutterPosition,
    position,
  } = props;

  const decorBorderStyles = cx(
    st.border,
    { [st[`border--width-${width}`]]: width },
    { [st[`border--color-${color}`]]: color },
    { [st[`border--radius-${radius}`]]: radius },
    { [st[`border--gutter-${gutter}`]]: gutter },
    { [st[`border--gutter-position-${gutterPosition}`]]: gutterPosition },
    { [st[`border--${position}`]]: position },
    className,
  );

  return <div className={decorBorderStyles}>{children}</div>;
};
