// Core
import { z } from "zod";

export const FormConsentCookiesEnum = {
  necessary: "necessary",
  analytical: "analytical",
} as const;

const i18NextPath = "cookies:";

export const inputConfig = {
  [FormConsentCookiesEnum.necessary]: {
    label: `${i18NextPath}necessary.title`,
  },
  [FormConsentCookiesEnum.analytical]: {
    label: `${i18NextPath}analytical.title`,
  },
};

export const consentCookiesForm = {
  shape: {
    [FormConsentCookiesEnum.necessary]: false,
    [FormConsentCookiesEnum.analytical]: false,
  },
  schema: () =>
    z.object({
      [FormConsentCookiesEnum.necessary]: z.boolean(),
      [FormConsentCookiesEnum.analytical]: z.boolean(),
    }),
};
