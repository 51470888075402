// Core
import nookies from "nookies";

// Utils
import { COOKIE_MAX_AGE } from "@/common/definitions/cookies";

class CookiesService {
  get = <T>(key: string): T | null => {
    const cookie = nookies.get()[key];
    return (cookie as T) || null;
  };

  set = (key: string, value: string, path = "/", maxAge = COOKIE_MAX_AGE): void => {
    nookies.set(null, key, value, { path, maxAge });
  };

  remove = (key: string): void => {
    nookies.destroy(null, key, { path: "/" });
  };

  fromJson = <T>(key: string): T | null => {
    const cookie = nookies.get()[key];
    return cookie ? (JSON.parse(cookie) as T) : null;
  };

  toJson = <T>(key: string, value: T, path = "/", maxAge = COOKIE_MAX_AGE) => {
    nookies.set(null, key, JSON.stringify(value), { path, maxAge });
  };

  toString = <T extends Partial<Record<string, string>>>(value: T) => {
    const list = Object.entries(value);

    return list.reduce((acc, item) => {
      const [name, valueName] = item;

      if (!name || !valueName) {
        return acc;
      }
      const itemStr = `${name}=${valueName}`;
      return acc + itemStr + ";";
    }, "");
  };
}

export const cookiesService = new CookiesService();
