import { routes } from "@/client/definitions/book";

export type MenuNavItemData = {
  id?: string;
  href: string;
  anchor?: string;
  icon?: string;
  asBlankLink?: boolean;
};

export type MenuData = {
  heading: string;
  items: MenuNavItemData[];
};

export const aboutBlockDataModel = {
  heading: "footer.navigation.about.heading",
  items: [
    {
      anchor: "footer.navigation.about.links.0",
      href: "/company-info",
    },
    {
      anchor: "footer.navigation.about.links.1",
      href: "/about",
    },
    {
      anchor: "footer.navigation.about.links.2",
      href: "/faq",
    },
  ],
};

export const supportBlockDataModel = {
  heading: "footer.navigation.support.heading",
  items: [
    {
      anchor: "footer.navigation.support.links.0",
      href: "/terms-conditions",
    },
    {
      anchor: "footer.navigation.support.links.1",
      href: routes.privacyPolicy,
    },
    {
      anchor: "footer.navigation.support.links.2",
      href: "/code-conduct",
    },
  ],
};

export const customerServiceDataModel = {
  heading: "footer.navigation.customerService.heading",
  items: [
    {
      anchor: "footer.navigation.customerService.links.0",
      href: "/payment",
    },
    {
      anchor: "footer.navigation.customerService.links.1",
      href: "/delivery",
    },
    {
      anchor: "footer.navigation.customerService.links.2",
      href: "/returns-refunds",
    },
    {
      anchor: "footer.navigation.customerService.links.3",
      href: "/core-exchange",
    },
  ],
};
