// Core
import type { Config } from "next-i18n-router/dist/types";

const I18N_DEFAULT_LOCALE = "en";
const I18N_DEFAULT_NS = "common";
export const I18N_LOCALES = [I18N_DEFAULT_LOCALE] as const;
export const I18N_NS = [I18N_DEFAULT_NS, "buttons"];

export type I18nLocalesType = (typeof I18N_LOCALES)[number];
export type DefaultNSType = typeof I18N_DEFAULT_NS;

const i18nConfig: Config = {
  defaultLocale: I18N_DEFAULT_LOCALE,
  locales: I18N_LOCALES,
  localeCookie: "atd-protrader-locale",
};

export default i18nConfig;
