"use client";
// Core
import { useTranslation, Trans } from "react-i18next";

// Components
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Title } from "@/client/components/ui/Typography/Title";
import { Text } from "@/client/components/ui/Typography/Text";
import { Button } from "@/client/components/ui/Button";
import { Link } from "@/client/components/others/link";
import { LayoutContainer } from "@/client/components/layouts/layout-container";
import { ConsentCookiesForm } from "../consent-cookies-form";

// Hooks
import { useConsentCookies } from "../../hooks/use-consent-cookies";
import { useToggle } from "@/client/hooks/use-toggle";

// Utils
import { routes } from "@/client/definitions/book";
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import st from "./styles.module.css";

export type ConsentCookiesProps = {
  testId?: string;
};

export const ConsentCookies = (props: ConsentCookiesProps) => {
  const { testId = "consent-cookies" } = props;

  const { t } = useTranslation(["cookies"]);

  const { isToggle: individualSettings, onToggle: setIndividualSettings } = useToggle(false);
  const { isOpenConsentCookies, onAllowAllCookies, onRejectAllCookies, setConsentCookies } =
    useConsentCookies();

  const handleSettings = () => {
    setIndividualSettings(true);
  };

  return isOpenConsentCookies ? (
    <div className={st["consent-cookies"]} data-testid={testId}>
      {!individualSettings ? (
        <LayoutContainer>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={24}>
              <Title tag="h4" size="16" color="gray-1000" className={st["consent-cookies__title"]}>
                {t("consentCookies.title")}
              </Title>
              <Text color="gray-1000">
                <Trans
                  i18nKey="consentCookies.description"
                  components={[<Link key={routes.privacyPolicy} href={routes.privacyPolicy} />]}
                />
              </Text>
            </Col>
          </Row>
          <Row
            gutter={ROW_GUTTER_TYPE.md.horizontal}
            justify="end"
            className={st["consent-cookies__actions"]}
          >
            <Col xs={24} md={7} lg={5} className={st["consent-cookies__button"]}>
              <Button
                size="large"
                onClick={onRejectAllCookies}
                data-testid={`${testId}-btn-reject`}
                block
                data-kmtx-cookies-refuse
              >
                <Text color="inherit" fontWeight="inherit">
                  {t("consentCookies.buttons.rejectCookies")}
                </Text>
              </Button>
            </Col>
            <Col xs={24} md={7} lg={5} className={st["consent-cookies__button"]}>
              <Button
                size="large"
                data-testid={`${testId}-btn-preferences`}
                onClick={handleSettings}
                block
                data-kmtx-cookies-customize
              >
                <Text color="inherit" fontWeight="inherit">
                  {t("consentCookies.buttons.managePreferences")}
                </Text>
              </Button>
            </Col>
            <Col xs={24} md={7} lg={5} className={st["consent-cookies__button"]}>
              <Button
                size="large"
                type="primary"
                data-testid={`${testId}-btn-allow-all`}
                onClick={onAllowAllCookies}
                block
                data-kmtx-cookies-allow
                data-kmtx-cookies-screen-type="general"
              >
                <Text color="inherit" fontWeight="inherit">
                  {t("consentCookies.buttons.allowCookies")}
                </Text>
              </Button>
            </Col>
          </Row>
        </LayoutContainer>
      ) : (
        <LayoutContainer>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={24}>
              <Title tag="h4" size="16" color="gray-1000" className={st["consent-cookies__title"]}>
                {t("consentCookies.individualTitle")}
              </Title>
              <ConsentCookiesForm
                onSubmit={setConsentCookies}
                onAllowAllCookies={onAllowAllCookies}
                testId={`${testId}-form`}
              />
            </Col>
          </Row>
        </LayoutContainer>
      )}
    </div>
  ) : null;
};
